import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getOrderReturnGraph } from '../graphql/OrderReturn';
import { queryToGraph } from '../helper/graphRequest';
import { updateStatusCar } from './carsSlice';

// const baseURL = 'https://carrental-9ijm.onrender.com/users';
const baseURL = 'https://rent-car-backend-ecxw.onrender.com/api';

const initialState = {
  status: 'idle',
  orders: [],
  isLoading: false,
  isModalOpen: false,
  error: ''
};

export const fetchReturnOrders = createAsyncThunk(
  'returnOrder/fetchReturnOrder',
  async (params) => {
    const response = await queryToGraph(getOrderReturnGraph(params)); //fetch(`${baseURL}/cars`);
    if (_.get(response, 'OrderReturn.isSuccess', false)) {
      return _.get(response, 'OrderReturn.result', []);
    }
    return _.get(response, 'OrderReturn.message', '');
  }
);

export const addReturnOrders = createAsyncThunk(
  'returnOrder/addReturnOrder',
  async (
    { comments, odometer, orderId, file, gasReturn, receivedBy },
    { dispatch }
  ) => {
    const formData = new FormData();
    formData.append('comments', comments);
    formData.append('odometer', odometer);
    formData.append('orderId', orderId);
    formData.append('gasReturn', gasReturn);
    formData.append('file', file);
    formData.append('receivedBy', receivedBy);
    const response = await axios.post(`${baseURL}/orderReturn`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    // Solo si la llamada es exitosa, ejecutamos updateStatusCar
    if (response.status === 200) {
      const statusVehicleStorage = JSON.parse(
        localStorage.getItem('statusVehicle')
      );

      const statusVehicleId = statusVehicleStorage?.find(
        (status) => status?.name === 'Libre'
      )?._id;

      if (statusVehicleId) {
        await dispatch(
          updateStatusCar({
            statusVehicleId,
            vehicleId: response?.data?.result?.orderId?.vehicleId
          })
        );
      } else {
        console.warn('No se encontró el estado "Libre" en localStorage');
      }
    }
    return response.data;
  }
);

export const updateReturnOrders = createAsyncThunk(
  'returnOrder/updatedReturnOrder',
  async ({ orderId, sellerId, clientId, vehicleId, startDate, endDate }) => {
    const response = await axios.patch(`${baseURL}/order/${orderId}`, {
      sellerId,
      clientId,
      vehicleId,
      startDate,
      endDate
    });
    return response.data;
  }
);

export const deleteReturnOrders = createAsyncThunk(
  'returnOrder/deleteReturnOrder',
  async ({ userId, favouriteId, carId }) => {
    await fetch(`${baseURL}/${userId}/favourites/${favouriteId}`, {
      method: 'DELETE'
    });
    return carId;
  }
);

const returnOrderSlice = createSlice({
  name: 'returnOrder',
  initialState,
  reducers: {
    // Add a reducer to reset status after a certain time
    resetStatus: (state) => ({
      ...state,
      status: 'idle'
    }),
    openModal: (state) => ({
      ...state,
      isModalOpen: true
    }),
    closeModal: (state) => ({
      ...state,
      isModalOpen: false
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReturnOrders.pending, (state) => ({
        ...state,
        isLoading: true,
        status: 'fetching'
      }))
      .addCase(fetchReturnOrders.fulfilled, (state, action) => ({
        ...state,
        status: 'success',
        isLoading: false,
        orders: action.payload
      }))
      .addCase(fetchReturnOrders.rejected, (state) => ({
        ...state,
        status: 'error',
        isLoading: false
      }))
      .addCase(addReturnOrders.pending, (state) => ({
        ...state,
        isLoading: true,
        status: 'adding'
      }))
      .addCase(addReturnOrders.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          toast.success('Reserva guardada!');
          return {
            ...state,
            status: 'added successfully',
            isLoading: false,
            isModalOpen: false,
            orders: [action.payload.result, ...state.orders]
          };
        }

        return {
          ...state,
          status: 'error',
          isLoading: false,
          isModalOpen: false,
          error: action.payload.errors
        };
      })
      .addCase(addReturnOrders.rejected, (state) => ({
        ...state,
        status: 'error',
        isModalOpen: false,
        isLoading: false
      }))
      .addCase(updateReturnOrders.pending, (state) => ({
        ...state,
        isLoading: true,
        status: 'updating'
      }))
      .addCase(updateReturnOrders.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          // Check if there are no errors
          toast.success('Reserva actualizada con éxito!');
          const newOrders = state.orders.map((order) => {
            if (order._id === action.payload.result._id) {
              return action.payload.result;
            }
            return order;
          });
          return {
            ...state,
            status: 'updated successfully',
            isLoading: false,
            isModalOpen: false,
            orders: newOrders,
            error: '' // Clear any previous errors
          };
        }

        toast.error(`Error: ${action.payload.message}`);
        return {
          ...state,
          status: 'error-updating',
          isLoading: false,
          error: action.payload.message
        };
      })
      .addCase(updateReturnOrders.rejected, (state) => ({
        ...state,
        status: 'error-updating',
        isLoading: false
        // error: action.payload.errors,
      }))
      .addCase(deleteReturnOrders.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(deleteReturnOrders.fulfilled, (state, action) => ({
        ...state,
        status: 'removed successfully',
        isLoading: false,
        orders: state.orders.filter(
          (order) => order.id !== parseInt(action.payload, 10)
        )
      }))
      .addCase(deleteReturnOrders.rejected, (state) => ({
        ...state,
        status: 'error',
        isLoading: false
      }));
  }
});

export const { resetStatus, openModal, closeModal } = returnOrderSlice.actions;
export default returnOrderSlice.reducer;
