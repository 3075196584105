/* eslint-disable */
export const getUserGraph = (params = null) => {
  return {
    query: `
      query User($rolId: String, $number: String, $email: String, $name: String, $userId: String) {
        User(rolId: $rolId, number: $number, email: $email, name: $name, id: $userId) {
          isSuccess
          message
          result {
            _id
            name
            email
            rolId {
              _id
              name
              rol
            }
            number
            status
            password
            license
            passport
            idDocument
            residenceAddress
            address
          }
          error
        }
      }`,
    ...(params && { variables: params })
  };
};
