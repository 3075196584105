/* eslint-disable */
export const getStatusVehiclesGraph = (params = null) => {
  return {
    query: `
        query StatusVehicle($statusVehicleId: String, $name: String, $order: String) {
        StatusVehicle(id: $statusVehicleId, name: $name, order: $order) {
          isSuccess
          message
          result {
            _id
            name
            order
          }
          error
        }
      }`,
    ...(params && { variables: params })
  };
};
