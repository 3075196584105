import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Icon from '../../Icons';
import Loader from '../../Loader';
import Year from '../../Year/Year';
import { checkUser } from '../../../redux/usersSlice';
import './styles.css';
import { Link } from 'react-router-dom';
import { ToggleButton, Tooltip } from '@mui/material';
const Form = ({ onSubmit, isLoading = false }) => {
  const transmissionTypes = useSelector((state) => state.car.transmissionTypes);
  const typeVehicles = useSelector((state) => state.car.typeVehicle);
  const userOwner = useSelector((state) => state.user.usersOwner);
  const fuelTypes = useSelector((state) => state.car.fuelTypes);
  const [formData, setFormData] = useState({
    idCar: '',
    brand: '',
    model: '',
    fuelId: '',
    transmissionId: '',
    typeVehicle: '',
    insurance: '',
    deductible: '',
    nPolicy: '',
    nAttendance: '',
    circulationCard: '',
    color: '',
    year: '',
    amountDoor: '',
    amountSeat: '',
    price: '',
    priceOwner: '',
    is4x4: false,
    imgCar: null,
    imageURL: '',
    name: '',
    email: '',
    number: '',
    ownerVehicle: ''
  });

  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showNewOwner, setShowNewOwner] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setErrorMessage(null);
    }
    if (name === 'deductible') {
      const numericValue = parseInt(value, 10);

      if (numericValue >= 0 && numericValue <= 100) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: numericValue
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: name === 'is4x4' ? event.target.checked : value
      }));
    }
  };

  const handleShowVehicle = () => {
    setShowNewOwner(!showNewOwner);
  };
  const handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        setPreviewImage(readerEvent.target?.result);
      };
      setFormData((prevData) => ({
        ...prevData,
        imgCar: file
      }));
    }
  };

  const handleClosePreviewImage = () => {
    setPreviewImage(null);
    setFormData((prevData) => ({
      ...prevData,
      imgCar: null
    }));
  };

  const handleEmailBlur = async () => {
    try {
      const response = await dispatch(
        checkUser({ email: formData.email, rolId: formData.rolId })
      ).unwrap();
      if (!response?.isSuccess) {
        setErrorMessage('El correo ya está registrado');
      } else {
        setErrorMessage(null);
      }
    } catch (error) {
      setErrorMessage('Error al validar el correo');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (errorMessage) {
      return;
    }
    onSubmit(formData);
  };

  const isValid =
    formData.amountDoor &&
    formData.amountSeat &&
    formData.brand &&
    formData.circulationCard &&
    formData.color &&
    formData.fuelId &&
    formData.idCar &&
    formData.imgCar &&
    formData.model &&
    formData.price &&
    formData.transmissionId &&
    formData.year &&
    formData.typeVehicle &&
    !showNewOwner
      ? formData.ownerVehicle
      : formData.name && formData.email && formData.number;

  return (
    <form className="custom-form" onSubmit={handleSubmit}>
      <div className="formTitleWrapper">
        <h2>Agregar auto</h2>
      </div>
      <h4 style={{ marginBottom: '0.5rem' }}>Detalles del auto</h4>
      <div className="formGroup">
        <label className="label" htmlFor="brand">
          Marca
          <input
            type="text"
            id="brand"
            name="brand"
            value={formData.brand}
            onChange={handleChange}
            required
            className="input"
            placeholder="Toyota"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Modelo
          <input
            type="text"
            id="model"
            name="model"
            value={formData.model}
            onChange={handleChange}
            required
            className="input"
            placeholder="Corolla"
          />
        </label>
      </div>

      <div className="formGroup">
        <label className="label" htmlFor="circulationCard">
          Tarjeta de circulación
          <input
            type="text"
            id="circulationCard"
            name="circulationCard"
            value={formData.circulationCard}
            onChange={handleChange}
            required
            className="input"
            placeholder="0210-230467-104-6"
          />
        </label>
      </div>

      <div className="formGroup">
        <label className="label" htmlFor="fuelId">
          Tipo de combustible
          <select
            className="basic"
            id="fuelId"
            name="fuelId"
            onChange={handleChange}
            value={formData.fuelId}
          >
            <option value="" disabled defaultChecked>
              Seleccionar tipo de gasolina
            </option>
            {fuelTypes?.map(({ _id: fuelId, name }) => (
              <option key={fuelId} value={fuelId}>
                {name}
              </option>
            ))}
          </select>
        </label>
        <label className="label" htmlFor="transmissionId">
          Transmisión
          <select
            className="basic"
            id="transmissionId"
            name="transmissionId"
            onChange={handleChange}
            value={formData.transmissionId}
          >
            <option value="" disabled defaultChecked>
              Seleccionar transmisión
            </option>
            {transmissionTypes?.map(({ _id: transmissionId, name }) => (
              <option key={transmissionId} value={transmissionId}>
                {name}
              </option>
            ))}
          </select>
        </label>
      </div>

      <div className="formGroup">
        <label className="label" htmlFor="color">
          Color
          <input
            type="text"
            id="color"
            name="color"
            value={formData.color}
            onChange={handleChange}
            required
            className="input"
            placeholder="Rojo"
          />
        </label>
        <label className="label" htmlFor="year">
          Año
          <Year value={formData.year} onChange={handleChange} />
        </label>
      </div>

      <div className="formGroup">
        <label className="label" htmlFor="amountDoor">
          Número de Puertas
          <input
            type="text"
            id="amountDoor"
            name="amountDoor"
            value={formData.amountDoor}
            onChange={handleChange}
            required
            className="input"
            placeholder="4"
          />
        </label>
        <label className="label" htmlFor="amountSeat">
          Número de asientos
          <input
            type="text"
            id="amountSeat"
            name="amountSeat"
            value={formData.amountSeat}
            onChange={handleChange}
            required
            className="input"
            placeholder="5"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="price">
          Precio
          <input
            type="text"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
            className="input"
            placeholder="Precio"
          />
        </label>
        <label className="label" htmlFor="priceOwner">
          Precio del dueño
          <input
            type="text"
            id="priceOwner"
            name="priceOwner"
            value={formData.priceOwner}
            onChange={handleChange}
            required
            className="input"
            placeholder="Precio del dueño"
          />
        </label>
      </div>
      <div className="formGroup" style={{ alignItems: 'center' }}>
        <label className="label" htmlFor="idCar">
          Placa
          <input
            type="text"
            id="idCar"
            name="idCar"
            value={formData.idCar}
            onChange={handleChange}
            required
            className="input"
            placeholder="P567395"
          />
        </label>
        <label className="label" htmlFor="typeVehicle">
          Tipo de Vehículo
          <select
            className="basic"
            id="typeVehicle"
            name="typeVehicle"
            onChange={handleChange}
            value={formData.typeVehicle}
          >
            <option value="" disabled defaultChecked>
              Seleccionar tipo de vehículo
            </option>
            {typeVehicles?.map(({ _id: typeVehicle, name }) => (
              <option key={typeVehicle} value={typeVehicle}>
                {name}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="formGroup" style={{ alignItems: 'center' }}>
        <label className="label" htmlFor="insurance">
          Aseguradora
          <input
            type="text"
            id="insurance"
            name="insurance"
            value={formData.insurance}
            onChange={handleChange}
            required
            className="input"
            placeholder="Asesuisa"
          />
        </label>
        <label className="label" htmlFor="deductible">
          Deducible %
          <input
            type="number"
            id="deductible"
            name="deductible"
            value={formData.deductible}
            onChange={handleChange}
            required
            className="input"
            placeholder="20"
            min="0"
            max="100"
          />
        </label>
      </div>
      <div className="formGroup" style={{ alignItems: 'center' }}>
        <label className="label" htmlFor="nPolicy">
          Número de póliza
          <input
            type="text"
            id="nPolicy"
            name="nPolicy"
            value={formData.nPolicy}
            onChange={handleChange}
            required
            className="input"
            placeholder="N° Póliza"
          />
        </label>
        <label className="label" htmlFor="nAttendance">
          Número de asistencia
          <input
            type="text"
            id="nAttendance"
            name="nAttendance"
            value={formData.nAttendance}
            onChange={handleChange}
            required
            className="input"
            placeholder="2298-8888"
          />
        </label>
      </div>
      <div className="formGroup" style={{ alignItems: 'center' }}>
        <label
          className="label"
          htmlFor="is4x4"
          style={{ marginTop: '1rem', marginLeft: '1rem' }}
        >
          Es 4x4
          <input
            type="checkbox"
            id="is4x4"
            name="is4x4"
            checked={formData.is4x4}
            onChange={handleChange}
            style={{ width: '20px', margin: '0px' }}
          />
        </label>
      </div>
      <div className="formGroup formImageGroup">
        <label htmlFor="image" className="uploadLabel">
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleImageChange}
            className="fileInput"
          />
          <span className="uploadText">Subir Imagen</span>
        </label>
        {formData.imgCar && (
          <div className="previewImageWrapper">
            <button className="iconWrapper" onClick={handleClosePreviewImage}>
              <Icon name="exit" width={30} height={30} />
            </button>
            <img src={previewImage} className="previewImage" />
          </div>
        )}
      </div>

      <h4 style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
        Detalles del propietario
      </h4>
      <label className="label" htmlFor="typeVehicle">
        Nombre
        <br />
        <div style={{ display: 'flex' }}>
          <select
            style={{ width: '90%' }}
            className="basic"
            id="ownerVehicle"
            name="ownerVehicle"
            onChange={handleChange}
            value={formData.ownerVehicle}
          >
            <option value="" disabled defaultChecked>
              Seleccionar propietario de vehiculo
            </option>
            {userOwner?.map(({ _id: userOwner, name, email }) => (
              <option key={userOwner} value={userOwner}>
                {name} ({email})
              </option>
            ))}
          </select>
          <div
            style={{
              width: '10%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center'
            }}
          >
            <Tooltip title="Agregar nuevo" placement="top">
              <ToggleButton
                style={{ marginTop: '10px' }}
                value="edit"
                onClick={() => handleShowVehicle()}
                aria-label="Editar o Agregar Clientes"
              >
                <Icon color={'gray'} stlye={{ marginTop: '8px' }} />
              </ToggleButton>
            </Tooltip>
          </div>
        </div>
      </label>
      {showNewOwner && (
        <>
          <div className="formGroup">
            <label className="label" htmlFor="name">
              Nombre
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="input"
                placeholder="Juan Carlos Rivera Gónzales"
              />
            </label>
          </div>

          <div className="formGroup">
            <label className="label" htmlFor="email">
              Correo
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleEmailBlur}
                required
                className="input"
                placeholder="juan.carlos@gmail.com"
              />
              {errorMessage && <p className="error">{errorMessage}</p>}{' '}
            </label>
            <label className="label" htmlFor="number">
              Teléfono
              <input
                type="text"
                id="number"
                name="number"
                value={formData.number}
                onChange={handleChange}
                required
                className="input"
                placeholder="78789056"
              />
            </label>
          </div>
        </>
      )}

      <div className="buttonsWrapper">
        <button
          type="submit"
          className="cardSecondaryButton"
          disabled={!isValid || errorMessage}
        >
          {isLoading ? <Loader stroke="#fff" /> : <span>Guardar</span>}
        </button>
      </div>
    </form>
  );
};

export default Form;
