import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import { isMobile } from 'react-device-detect';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import banner from '../../assets/home-banner.jpg';
import {
  addCar,
  closeModal,
  fetchCars,
  fetchFuelTypes,
  fetchStatusVehicles,
  fetchTransmissionTypes,
  fetchTypeVehicle,
  openModal
} from '../../redux/carsSlice';
import '../../styles/main.css';
import AppModal from '../Modal';
import Year from '../Year/Year';
import Navbar from '../navbar';
import Form from './Form';
import { useFilters } from './hooks/useFilters';
import GalleryTableToggle from './GalleryTableToggle';
import Icon from '../Icons';
import { fetchSummaryGraph } from '../../redux/summary';
import { fetchUsersOwner } from '../../redux/usersSlice';

const Main = () => {
  const cars = useSelector((state) => state.car.cars);
  const loading = useSelector((state) => state.car.isLoading);
  const status = useSelector((state) => state.car.status);
  const auth = useSelector((state) => state.auth);
  const fuelTypes = useSelector((state) => state.car.fuelTypes);
  const statusVehicle = useSelector((state) => state.car.statusVehicle);

  const optionsSettings = useSelector((state) => state.options);
  const dispatch = useDispatch();

  const [number, setNumber] = useState(1);
  const navigate = useNavigate();
  const isModalOpen = useSelector((state) => state.car.isModalOpen);
  const showPerPage = 6;
  const lastNumber = number * showPerPage;
  const firstNumber = lastNumber - showPerPage;
  const car = cars.slice(firstNumber, lastNumber);
  const {
    filters,
    isFiltered,
    clearFilters,
    handleChangeFilter,
    handleSearch
  } = useFilters();

  const prev = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  const next = () => {
    if (number < Math.ceil(cars?.length / showPerPage)) {
      setNumber(number + 1);
    }
  };

  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleCreateCar = (carDetails) => {
    const statusVehicleStorage = JSON.parse(
      localStorage.getItem('statusVehicle')
    );

    dispatch(
      addCar({
        ...carDetails,
        statusVehicleId: (statusVehicle.length > 0
          ? statusVehicle
          : statusVehicleStorage
        )?.find((status) => status?.name === 'Libre')?._id
      })
    );
  };

  useEffect(() => {
    const userDataFromStorage = JSON.parse(localStorage.getItem('user'));
    dispatch(fetchCars());
    dispatch(fetchTransmissionTypes());
    dispatch(fetchFuelTypes());
    dispatch(fetchTypeVehicle());
    dispatch(fetchSummaryGraph({ sellerId: userDataFromStorage?.id }));
    dispatch(fetchUsersOwner());
    dispatch(fetchStatusVehicles());
  }, [dispatch]);

  return (
    <div className="page_container">
      <Navbar />
      <div className="main-banner">
        <img src={banner} alt="banner" className="banner-img" />
        <div className="main-banner-text">
          {loading && status === 'fetching' && (
            <h2 className="heading">Cargando los últimos vehiculos...</h2>
          )}
          <div className="search-form">
            <div className="filter-container">
              <input
                type="text"
                id="brand"
                name="brand"
                className="filter-input"
                placeholder="Marca"
                onChange={handleChangeFilter}
                value={filters?.brand || ''}
              />
              <input
                type="text"
                id="model"
                name="model"
                className="filter-input"
                placeholder="Modelo"
                onChange={handleChangeFilter}
                value={filters?.model || ''}
              />
              <input
                type="text"
                id="color"
                name="color"
                className="filter-input"
                placeholder="Color"
                onChange={handleChangeFilter}
                value={filters?.color || ''}
              />
              <select
                className="filter-input"
                id="fuelId"
                name="fuelId"
                onChange={handleChangeFilter}
                value={filters?.fuelId || ''}
              >
                <option
                  value=""
                  disabled
                  defaultChecked
                  className="defaultOption"
                >
                  Tipo de gasolina
                </option>
                {fuelTypes?.map(({ _id: fuelId, name }) => (
                  <option key={fuelId} value={fuelId}>
                    {name}
                  </option>
                ))}
              </select>

              <Year
                customClassName="filter-select"
                onChange={handleChangeFilter}
                value={filters?.year || ''}
              />
              {!isFiltered ? (
                <button
                  type="button"
                  onClick={handleSearch}
                  className="search-btn"
                >
                  Buscar
                </button>
              ) : (
                <button
                  type="button"
                  className="search-btn"
                  onClick={clearFilters}
                >
                  Limpiar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="createButtonWrapper create-car"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <div>
          <button className="button" onClick={handleOpenModal}>
            Agregar auto
          </button>
        </div>
        <div>
          <GalleryTableToggle />
        </div>
      </div>

      {car?.length ? (
        optionsSettings.modeView == 'gallery' ? (
          <div className="main-container">
            <button
              className={
                number === 1
                  ? 'arrow-btn prev-btn disabled'
                  : 'arrow-btn prev-btn'
              }
              type="button"
              onClick={prev}
            >
              <BsArrowLeft className="arrow" />
            </button>

            {car?.map((car) => (
              <Card sx={{ width: '100%' }} key={car?._id}>
                <CardMedia
                  sx={{ height: 220 }}
                  image={car?.imgCar}
                  title={car?.brand}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {car?.brand}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ${Math.round(car?.price)}/dia
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">
                    <Link
                      to={`/cars/${car?._id}`}
                      key={car?._id}
                      className="btn"
                    >
                      Ver detalles
                    </Link>
                  </Button>
                </CardActions>
              </Card>
            ))}

            <button
              className={
                number >= cars.length / showPerPage
                  ? 'arrow-btn next-btn disabled'
                  : 'arrow-btn next-btn'
              }
              type="button"
              onClick={next}
            >
              <BsArrowRight className="arrow" />
            </button>
          </div>
        ) : (
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                width: '80%'
              }}
            >
              <table className="content-table">
                <thead>
                  <tr>
                    <th>Vehiculo</th>
                    {!isMobile && (
                      <>
                        <th>Marca</th>
                        <th>Modelo</th>
                        <th>Propietario</th>
                        <th>Precio/Dia</th>
                      </>
                    )}
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {cars?.map((car) => (
                    <>
                      <tr key={car._id}>
                        <td>
                          <CardMedia
                            component="img"
                            sx={{ width: 100, height: 100, borderRadius: 2 }}
                            image={car?.imgCar}
                            title={car?.brand}
                          />
                        </td>
                        {!isMobile && (
                          <>
                            <td>{car?.brand}</td>
                            <td>{car?.model}</td>
                            <td>{car?.vehicleOwner.name}</td>
                            <td>${Math.round(car?.price)}/dia</td>
                          </>
                        )}
                        <td>
                          <div className="iconsWrapper">
                            <button
                              className="iconButton deleteButton"
                              onClick={() => navigate(`/cars/${car?._id}`)}
                            >
                              <span>
                                <Icon name="eye" />
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      ) : (
        <h2 className="heading" style={{ width: '100%' }}>
          ¡Ups! Parece que no hay ningún automóvil que coincida con sus
          requisitos.
        </h2>
      )}

      {isModalOpen && (
        <AppModal onClose={handleCloseModal}>
          <Form
            onSubmit={handleCreateCar}
            isLoading={loading && status === 'adding'}
          />
        </AppModal>
      )}
    </div>
  );
};

export default Main;
