import React, { useEffect, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fetchCars,
  fetchFuelTypes,
  fetchTransmissionTypes,
  fetchTypeVehicle,
  updateCar
} from '../../redux/carsSlice';
import '../../styles/detail.css';
import Year from '../Year/Year';

const EditCar = () => {
  const { id } = useParams();
  const userData = JSON.parse(localStorage.getItem('user'));
  const cars = useSelector((state) => state.car.cars);
  const car = cars.find((car) => car._id === id);
  const fuelTypes = useSelector((state) => state.car.fuelTypes);
  const transmissionTypes = useSelector((state) => state.car.transmissionTypes);
  const typeVehicles = useSelector((state) => state.car.typeVehicle);
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState(null);

  const [formData, setFormData] = useState({
    idCar: '',
    brand: '',
    model: '',
    fuelId: '',
    transmissionId: '',
    typeVehicle: '',
    circulationCard: '',
    color: '',
    year: '',
    amountDoor: '',
    amountSeat: '',
    price: '',
    insurance: '',
    deductible: '',
    nPolicy: '',
    nAttendance: '',
    priceOwner: '',
    is4x4: false,
    imgCar: null,
    imageURL: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'deductible') {
      const numericValue = parseInt(value, 10);
      if (numericValue >= 0 && numericValue <= 100) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: numericValue
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: name === 'is4x4' ? event.target.checked : value
      }));
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        setPreviewImage(readerEvent.target?.result);
      };
      setFormData((prevData) => ({
        ...prevData,
        imgCar: file
      }));
    }
  };

  useEffect(() => {
    dispatch(fetchCars());
    dispatch(fetchTransmissionTypes());
    dispatch(fetchFuelTypes());
    dispatch(fetchTypeVehicle());
  }, [dispatch]);

  useEffect(() => {
    const idCar = car?.idCar;
    const brand = car?.brand;
    const color = car?.color;
    const year = car?.year;
    const imgCar = car?.imgCar;
    const is4x4 = car?.is4x4;
    const fuelId = car?.fuelId;
    const transmissionId = car?.transmissionId;
    const circulationCard = car?.circulationCard;
    const amountDoor = car?.amountDoor;
    const amountSeat = car?.amountSeat;
    const model = car?.model;
    const price = car?.price;
    const typeVehicle = car?.typeVehicle;
    const priceOwner = car?.priceOwner;

    setFormData({
      idCar,
      brand,
      model,
      fuelId,
      transmissionId,
      circulationCard,
      color,
      year,
      amountDoor,
      amountSeat,
      price,
      priceOwner,
      is4x4,
      insurance: car?.insurance,
      deductible: car?.deductible,
      nPolicy: car?.nPolicy,
      nAttendance: car?.nAttendance,
      typeVehicle
    });
    setPreviewImage(imgCar);
  }, [car]);

  const handleSave = () => {
    dispatch(updateCar({ ...formData, id }));
    navigate(`/cars/${id}`);
  };

  if (!car) {
    return <h1 className="heading">Loading Car Detail...Please Wait</h1>;
  }

  return (
    <section id="detail">
      <header className="header">
        <IoIosArrowBack
          className="back-btn"
          onClick={() => navigate(`/cars/${id}`)}
        />
        <h2 className="title" style={{ color: 'white' }}>
          {car.brand}
        </h2>
      </header>

      <div className="detail-container">
        <div className="banner">
          <div className="img-container">
            <img src={previewImage} alt={car.brand} className="img" />
          </div>

          <label htmlFor="image" className="uploadLabel">
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
              className="fileInput"
            />
            <span className="uploadText">Subir Imagen</span>
          </label>
        </div>
        <div className="detail-wrapper">
          <div className="details">
            <div className="img-text">
              <div className="rating">
                <AiOutlineUser className="user-icon" />
                <div>
                  <p>Vendedor: {userData ? userData.name : 'Unknown User'}</p>
                </div>
              </div>
            </div>

            <div className="features-table">
              <div className="feature-cell bold">
                <span>Precio</span>
              </div>
              <div className="feature-cell">
                <input
                  type="text"
                  id="price"
                  name="price"
                  required
                  value={formData.price}
                  onChange={handleChange}
                  className="cell-input"
                  placeholder="$30.00"
                />
              </div>
              <div className="feature-cell bold">
                <span>Precio del dueño</span>
              </div>
              <div className="feature-cell">
                <input
                  type="text"
                  id="priceOwner"
                  name="priceOwner"
                  required
                  value={formData.priceOwner}
                  onChange={handleChange}
                  className="cell-input"
                  placeholder="$60.00"
                />
              </div>
              <div className="feature-cell bold">
                <span>Marca</span>
              </div>
              <div className="feature-cell">
                <input
                  type="text"
                  id="brand"
                  name="brand"
                  required
                  value={formData.brand}
                  onChange={handleChange}
                  className="cell-input"
                  placeholder="Toyota"
                />
              </div>
              <div className="feature-cell bold">
                <span>Modelo</span>
              </div>
              <div className="feature-cell">
                <input
                  type="text"
                  id="model"
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                  required
                  className="cell-input"
                  placeholder="Corolla"
                />
              </div>
              <div className="feature-cell bold">
                <span>Año</span>
              </div>
              <div className="feature-cell">
                <Year customClassName="cell-select" />
              </div>
              <div className="feature-cell bold">
                <span>Combustible</span>
              </div>
              <div className="feature-cell">
                <select
                  className="cell-select"
                  id="fuelId"
                  name="fuelId"
                  onChange={handleChange}
                  value={formData.fuelId}
                >
                  <option value="" disabled defaultChecked>
                    Seleccionar tipo de combustible
                  </option>
                  {fuelTypes?.map(({ _id: fuelId, name }) => (
                    <option key={fuelId} value={fuelId}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="feature-cell bold">
                <span>No. Puertas</span>
              </div>
              <div className="feature-cell">
                <input
                  type="text"
                  id="amountDoor"
                  name="amountDoor"
                  value={formData.amountDoor}
                  onChange={handleChange}
                  required
                  className="cell-input"
                  placeholder="4"
                />
              </div>
              <div className="feature-cell bold">
                <span>No. Asientos</span>
              </div>
              <div className="feature-cell">
                <input
                  type="text"
                  id="amountSeat"
                  name="amountSeat"
                  value={formData.amountSeat}
                  onChange={handleChange}
                  required
                  className="cell-input"
                  placeholder="5"
                />
              </div>
              <div className="feature-cell bold">
                <span>Color</span>
              </div>
              <div className="feature-cell">
                <input
                  type="text"
                  id="color"
                  name="color"
                  value={formData.color}
                  onChange={handleChange}
                  required
                  className="cell-input"
                  placeholder="Rojo"
                />
              </div>
              <div className="feature-cell bold">
                <span>4x4</span>
              </div>
              <div className="feature-cell">
                <input
                  type="checkbox"
                  id="is4x4"
                  name="is4x4"
                  checked={formData.is4x4}
                  onChange={handleChange}
                  style={{ width: '20px', margin: '0px' }}
                />
              </div>
              <div className="feature-cell bold">
                <span>Transmisión</span>
              </div>
              <div className="feature-cell">
                <select
                  className="cell-select"
                  id="transmissionId"
                  name="transmissionId"
                  onChange={handleChange}
                  value={formData.transmissionId}
                >
                  <option value="" disabled defaultChecked>
                    Seleccionar transmisión
                  </option>
                  {transmissionTypes?.map(({ _id: transmissionId, name }) => (
                    <option key={transmissionId} value={transmissionId}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="feature-cell bold">
                <span>Tipo de Vehículo</span>
              </div>
              <div className="feature-cell">
                <select
                  className="cell-select"
                  id="typeVehicle"
                  name="typeVehicle"
                  onChange={handleChange}
                  value={formData.typeVehicle}
                >
                  <option value="" disabled defaultChecked>
                    Seleccionar tipo de vehículo
                  </option>
                  {typeVehicles?.map(({ _id: typeVehicle, name }) => (
                    <option key={typeVehicle} value={typeVehicle}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="feature-cell bold">
                <span>Aseguradora</span>
              </div>
              <div className="feature-cell">
                <input
                  type="text"
                  id="insurance"
                  name="insurance"
                  value={formData.insurance}
                  onChange={handleChange}
                  required
                  className="cell-input"
                  placeholder="Aseguradora"
                />
              </div>
              <div className="feature-cell bold">
                <span>Deducible %</span>
              </div>
              <div className="feature-cell">
                <input
                  type="number"
                  id="deductible"
                  name="deductible"
                  value={formData.deductible}
                  onChange={handleChange}
                  required
                  className="cell-input"
                  placeholder="Deducible"
                  min="0"
                  max="100"
                />
              </div>
              <div className="feature-cell bold">
                <span>Número de poliza</span>
              </div>
              <div className="feature-cell">
                <input
                  type="text"
                  id="nPolicy"
                  name="nPolicy"
                  value={formData.nPolicy}
                  onChange={handleChange}
                  required
                  className="cell-input"
                  placeholder="N° Póliza"
                />
              </div>
              <div className="feature-cell bold">
                <span>Número de asistencia</span>
              </div>
              <div className="feature-cell">
                <input
                  type="text"
                  id="nAttendance"
                  name="nAttendance"
                  value={formData.nAttendance}
                  onChange={handleChange}
                  required
                  className="cell-input"
                  placeholder="N° Asistencia"
                />
              </div>
            </div>
          </div>

          <div className="reserve-container">
            <button type="button" className="reserve-btn" onClick={handleSave}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditCar;
