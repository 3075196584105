import { useEffect, useState } from 'react';
import Loader from '../../Loader';
import './styles.css';
import { roles } from '../../../constants/system';
const Form = ({ onSubmit, userData, isLoading = false }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    password: '',
    rolId: roles.seller,
    switchPsw: false
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name == 'switchPsw' ? event.target.checked : value
    }));
    if (name == 'switchPsw') {
      setFormData((prevData) => ({
        ...prevData,
        password: ''
      }));
    }
  };

  const handleCheckboxChange = (rolId) => {
    setFormData((prevData) => ({ ...prevData, rolId }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    onSubmit(formData);
  };

  useEffect(() => {
    if (userData?._id) {
      setFormData({
        ...userData,
        rolId: userData.rolId._id
      });
    } else {
      setFormData({
        ...userData,
        rolId: userData?.rolId,
        switchPsw: true
      });
    }
  }, [userData]);

  return (
    <form className="custom-form" onSubmit={handleSubmit}>
      <div className="formTitleWrapper">
        <h2>
          {formData?._id
            ? `Editar ${
                formData.rolId === '6545a6178eee82df3614e720'
                  ? 'Vendedor'
                  : 'Administrador'
              }`
            : 'Agregar usuario'}
        </h2>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Nombre
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="input"
            placeholder="Juan Peréz"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Correo
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="input"
            placeholder="juan.perez@gmail.com"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          ¿Cambiar contraseña?
          <input
            type="checkbox"
            id="switchPsw"
            name="switchPsw"
            checked={formData?.switchPsw || false} // Asegura que sea booleano
            onChange={handleChange}
            style={{ marginLeft: '40px', height: '15px', width: '15px' }}
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Teléfono
          <input
            type="text"
            id="phone"
            name="number"
            value={formData.number}
            onChange={handleChange}
            required
            className="input"
            placeholder="7878 6296"
          />
        </label>
      </div>
      {formData?.switchPsw && (
        <div className="formGroup">
          <label className="label" htmlFor="password">
            Contraseña
            <input
              type="text"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="input"
              placeholder="Contraseña"
            />
          </label>
        </div>
      )}
      <div className="formGroup">
        <div className="input-radio-wrapper">
          <label className="input-radio-label">
            <input
              type="radio"
              className="input-radio off"
              checked={formData.rolId === roles.seller}
              name="pilih"
              onChange={() => handleCheckboxChange(roles.seller)}
            />{' '}
            Vendedor
          </label>
          <label className="input-radio-label">
            <input
              type="radio"
              className="input-radio on"
              name="pilih"
              checked={formData.rolId === roles.admin}
              onChange={() => handleCheckboxChange(roles.admin)}
            />{' '}
            Administrador
          </label>
        </div>
      </div>

      <div className="buttonsWrapper">
        <button
          type="submit"
          className="cardSecondaryButton"
          disabled={!formData.name || !formData.email}
        >
          {isLoading ? <Loader stroke="#fff" /> : <span>Guardar</span>}
        </button>
      </div>
    </form>
  );
};

export default Form;
