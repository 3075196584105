import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import { addCar, resetStatus } from '../redux/carsSlice';
import '../styles/addCar.css';
import Navbar from './navbar';

const AddCar = () => {
  const userDataFromStorage = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.car);

  const [carDetails, setCarDetails] = useState({
    name: '',
    price: 0,
    ratings: 0,
    image: '',
    description: '',
    carType: '',
    door: 2,
    seat: 2,
    bag: 0,
    minGas: 0,
    maxGas: 0
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCarDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const {
      name,
      price,
      ratings,
      image,
      description,
      carType,
      door,
      seat,
      bag,
      minGas,
      maxGas
    } = carDetails;

    const newCar = {
      name,
      price,
      ratings,
      image,
      description,
      carType,
      door,
      seat,
      bag,
      minGas,
      maxGas,
      user_id: userDataFromStorage.id
    };

    dispatch(addCar({ userId: userDataFromStorage.id, car: newCar }));

    setCarDetails({
      name: '',
      price: 0,
      ratings: 0,
      image: '',
      description: '',
      carType: '',
      door: 2,
      seat: 2,
      bag: 0,
      minGas: 0,
      maxGas: 0
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (data.status === 'added successfully') {
      navigate(`/cars/${data.cars[data.cars.length - 1].id}`);
      setTimeout(() => {
        dispatch(resetStatus());
      }, 2000);
    }
  }, [data, navigate, dispatch]);

  return (
    <div className="page_container">
      <div className="add_car_container">
        <Navbar />
        <div className="add_car_wrapper">
          <div className="car_form_container">
            {data.status === 'error' ? (
              <p className="error">{data.error}</p>
            ) : null}
            <h2 className="heading">Agrega un auto</h2>
            <form className="add_car_form_wrapper" onSubmit={handleSubmit}>
              <div className="first_wrapper">
                <label htmlFor="name">
                  Marca:
                  <input
                    id="brand"
                    type="text"
                    name="name"
                    value={carDetails.name}
                    onChange={handleChange}
                    placeholder="Toyota Corolla"
                    required
                  />
                </label>
                <label htmlFor="type">
                  Tarjeta de circulación:
                  <input
                    id="type"
                    type="text"
                    name="carType"
                    value={carDetails.carType}
                    onChange={handleChange}
                    placeholder="0210-230467-104-6"
                    required
                  />
                </label>
                <label htmlFor="color">
                  Color
                  <input
                    id="color"
                    type="text"
                    name="carColor"
                    placeholder="Blue"
                    required
                  />
                </label>
              </div>

              <div className="second_wrapper">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.45rem'
                  }}
                >
                  <label htmlFor="is4x4">4x4:</label>
                  <input
                    id="is4x4"
                    type="checkbox"
                    name="is4x4"
                    style={{
                      width: '20px',
                      margin: '0px'
                    }}
                  />
                </div>
                {/*  <label htmlFor="rating">
                  Ratings:
                  <input
                    id="rating"
                    type="number"
                    name="ratings"
                    min={0}
                    max={5}
                    value={carDetails.ratings}
                    onChange={handleChange}
                    required
                  />
                </label> */}
                <label htmlFor="price">
                  Precio:
                  <input
                    id="price"
                    type="number"
                    name="price"
                    min={0}
                    value={carDetails.price}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div className="second_wrapper">
                <label htmlFor="door">
                  Puertas:
                  <input
                    id="door"
                    type="number"
                    name="door"
                    min={2}
                    max={4}
                    value={carDetails.door}
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="seat">
                  Asientos:
                  <input
                    id="seat"
                    type="number"
                    name="seat"
                    min={2}
                    value={carDetails.seat}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="second_wrapper">
                <label htmlFor="bag">
                  Bolsas:
                  <input
                    id="bag"
                    type="number"
                    name="bag"
                    value={carDetails.bag}
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="year">
                  Year
                  <select className="basic" id="year" name="year">
                    <option value="" disabled>
                      year
                    </option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                    <option value="2018">2018</option>
                    <option value="2017">2017</option>
                    <option value="2016">2016</option>
                    <option value="2015">2015</option>
                    <option value="2014">2014</option>
                    <option value="2013">2013</option>
                    <option value="2012">2012</option>
                    <option value="2011">2011</option>
                    <option value="2010">2010</option>
                    <option value="2009">2009</option>
                    <option value="2008">2008</option>
                    <option value="2007">2007</option>
                    <option value="2006">2006</option>
                    <option value="2005">2005</option>
                    <option value="2004">2004</option>
                    <option value="2003">2003</option>
                    <option value="2002">2002</option>
                    <option value="2001">2001</option>
                    <option value="2000">2000</option>
                    <option value="1999">1999</option>
                    <option value="1998">1998</option>
                    <option value="1997">1997</option>
                    <option value="1996">1996</option>
                    <option value="1995">1995</option>
                    <option value="1994">1994</option>
                    <option value="1993">1993</option>
                    <option value="1992">1992</option>
                    <option value="1991">1991</option>
                    <option value="1990">1990</option>
                    <option value="1989">1989</option>
                    <option value="1988">1988</option>
                    <option value="1987">1987</option>
                    <option value="1986">1986</option>
                    <option value="1985">1985</option>
                    <option value="1984">1984</option>
                    <option value="1983">1983</option>
                    <option value="1982">1982</option>
                    <option value="1981">1981</option>
                    <option value="1980">1980</option>
                    <option value="1979">1979</option>
                    <option value="1978">1978</option>
                    <option value="1977">1977</option>
                    <option value="1976">1976</option>
                    <option value="1975">1975</option>
                    <option value="1974">1974</option>
                    <option value="1973">1973</option>
                    <option value="1972">1972</option>
                    <option value="1971">1971</option>
                    <option value="1970">1970</option>
                  </select>
                </label>
              </div>
              <label htmlFor="carPhoto">
                Url de la imagen del auto:
                <textarea
                  id="carPhoto"
                  className="carPhoto"
                  name="image"
                  value={carDetails.image}
                  onChange={handleChange}
                  required
                />
              </label>
              <label htmlFor="carDetails">
                Notas:
                <textarea
                  id="carDetails"
                  name="description"
                  value={carDetails.description}
                  onChange={handleChange}
                  required
                />
              </label>
              <div className="button_wrapper">
                <button
                  type="button"
                  className="cancel_btn"
                  onClick={handleBack}
                >
                  Cancelar
                </button>
                <button type="submit" className="btn">
                  {data.status === 'adding' ? (
                    <BeatLoader
                      loading={data.status}
                      size={9}
                      color="#ffffff"
                    />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCar;
