import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomProvider, DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import es_ES from 'rsuite/locales/es_ES';
import { fetchUsers } from '../../../redux/usersSlice';
import { fetchReservationOrders } from '../../../redux/reservationOrdersSlice';
import Loader from '../../Loader';
import '../Form/styles.css';
import { roles } from '../../../constants/system';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ToggleButton, Tooltip } from '@mui/material';
import Icon from '../../Icons';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';

const Form = ({ onSubmit }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('user'));
  const orders = useSelector((state) => state.reservationOrder.orders);
  const users = useSelector((state) => state.user.users);
  const clients = users?.filter((user) => user.rolId?._id === roles.client);
  const clientsOptions = clients
    ?.filter((clientSingle) => clientSingle?.status)
    ?.map((client) => ({
      label: client.name,
      id: client._id
    }));
  const status = useSelector((state) => state.reservationOrder.status);
  const isLoading = useSelector((state) => state.reservationOrder.isLoading);

  const [clientSelected, setClientSelected] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    license: '',
    idDocument: '',
    amount: 0,
    deposit: 0,
    amountDescription: '',
    rolId: roles.client
  });

  const handleClientChange = (event, value) => {
    setClientSelected(value?.id);
  };

  const isDateOverlap = (newStart, newEnd, existingStart, existingEnd) => {
    return newStart < existingEnd && newEnd > existingStart;
  };
  const handleDateChange = (date) => {
    const startTimestamp = new Date(date?.[0]).getTime();
    const endTimestamp = new Date(date?.[1]).getTime();

    let overlappingOrder = null;
    for (const order of orders) {
      if (_.isEqual(id, order?.vehicleId?._id)) {
        const orderStart = Number(order?.startDate);
        const orderEnd = Number(order?.endDate);
        if (isDateOverlap(startTimestamp, endTimestamp, orderStart, orderEnd)) {
          overlappingOrder = order; // Guardar la orden que se superpone
          break; // Rompe el bucle si se encuentra una superposición
        }
      }
    }

    if (overlappingOrder) {
      const orderStartDate = new Date(
        Number(overlappingOrder?.startDate)
      ).toLocaleDateString('es-ES');
      const orderEndDate = new Date(
        Number(overlappingOrder?.endDate)
      ).toLocaleDateString('es-ES');
      toast.error(
        `Las fechas seleccionadas se superponen con una orden existente: Fecha de inicio: ${orderStartDate}, Fecha de finalización:  ${orderEndDate}`
      );
    } else {
      setStartDate(startTimestamp);
      setEndDate(endTimestamp);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    onSubmit({
      clientId: clientSelected,
      startDate,
      endDate,
      sellerId: userData.id,
      ...formData
    });
  };

  const shouldDisableDate = (date) => {
    // const currentDate = new Date(date);
    // const startDate = new Date(parseInt(orders[0]?.startDate));
    // const endDate = new Date(parseInt(orders[0]?.endDate));

    const currentDate = new Date(date);
    const startDate = new Date(parseInt(orders[0]?.startDate));
    const endDate = new Date(parseInt(orders[0]?.endDate));

    return currentDate >= startDate && currentDate <= endDate;
  };

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchReservationOrders());
  }, [dispatch]);

  return (
    <form className="custom-form" onSubmit={handleSubmit}>
      <div className="formTitleWrapper">
        <h2>Reservar</h2>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <CustomProvider locale={es_ES}>
          <DateRangePicker
            size="lg"
            block
            showOneCalendar
            locate={'es_ES'}
            placeholder="Selecciona fechas de reserva"
            // shouldDisableDate={shouldDisableDate}
            onChange={handleDateChange}
          />
        </CustomProvider>
      </div>
      <div style={{ width: '100%', display: 'flex' }}>
        <div style={{ width: '95%' }}>
          <Autocomplete
            disablePortal
            isOptionEqualToValue={(option, value) => option.id === value.id}
            id="combo-box-demo"
            options={clientsOptions}
            onChange={handleClientChange}
            renderInput={(params) => <TextField {...params} label="Clientes" />}
          />
        </div>
        <div
          style={{
            width: '10%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center'
          }}
        >
          <Link to={`/clients`}>
            <Tooltip title="Editar o Agregar Clientes" placement="top">
              <ToggleButton
                style={{ paddingBottom: '20px' }}
                value="edit"
                aria-label="Editar o Agregar Clientes"
              >
                <Icon color={'gray'} stlye={{ marginTop: '10px' }} />
              </ToggleButton>
            </Tooltip>
          </Link>
        </div>
      </div>
      <br />
      <div className="formGroup">
        <label className="label" htmlFor="deposit">
          Depósito
          <input
            type="text"
            id="deposit"
            name="deposit"
            value={formData.deposit}
            onChange={handleChange}
            className="input"
            placeholder="100"
          />
        </label>
      </div>
      <h4 style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
        Conductor designado
      </h4>

      <div className="formGroup">
        <label className="label" htmlFor="name">
          Nombre
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="input"
            placeholder="Juan Peréz"
          />
        </label>
      </div>

      <div className="formGroup">
        <label className="label" htmlFor="model">
          Licencia
          <input
            type="text"
            id="license"
            name="license"
            value={formData.license}
            onChange={handleChange}
            className="input"
            placeholder="09876780"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Documento de identidad
          <input
            type="text"
            id="idDocument"
            name="idDocument"
            value={formData.idDocument}
            onChange={handleChange}
            className="input"
            placeholder="068594628-0"
          />
        </label>
      </div>

      <h4 style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
        Costos Extras
      </h4>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Monto
          <input
            type="text"
            id="amount"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            className="input"
            placeholder="Brk New Orlands 12-b"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Descripción
          <input
            type="text"
            id="amountDescription"
            name="amountDescription"
            value={formData.amountDescription}
            onChange={handleChange}
            className="input"
          />
        </label>
      </div>

      <div className="buttonsWrapper">
        <button
          type="submit"
          className="cardSecondaryButton"
          disabled={!clientSelected || !startDate || !endDate}
        >
          {isLoading && status === 'adding' ? (
            <Loader stroke="#fff" />
          ) : (
            <span>Reservar</span>
          )}
        </button>
      </div>
    </form>
  );
};

export default Form;
