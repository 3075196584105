import Loader from '../Loader';
import AppModal from '../Modal';
import './styles.css';

const Alert = ({
  title,
  description,
  primaryAction,
  onCloseAlert,
  type = 'send',
  isLoading = false
}) => {
  return (
    <AppModal onClose={onCloseAlert}>
      <div>
        <div className="alertTextWrapper">
          <h2>{title || ''}</h2>

          <p>{description || ''}</p>
        </div>
        <div className="alertButtonsWrapper">
          <button
            type="button"
            className="button cancel"
            onClick={onCloseAlert}
          >
            <span>Cancelar</span>
          </button>
          <button
            type="submit"
            onClick={primaryAction}
            className={type === 'delete' ? 'button danger' : 'button'}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <span>{type === 'delete' ? 'Eliminar' : 'Enviar'}</span>
            )}
          </button>
        </div>
      </div>
    </AppModal>
  );
};

export default Alert;
